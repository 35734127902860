(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
/* global $, io */

var canvas = document.getElementById('loci');
var scene = new THREE.Scene();

var w = window.innerWidth,
  h = window.innerHeight;
//////////////
// RENDERER //
//////////////
var renderer;
// create and start the renderer; choose antialias setting.
if (Detector.webgl) {
  renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true
  });
} else {
  renderer = new THREE.CanvasRenderer({
    canvas: canvas
  });
}

var adjustSize = () => {
  console.log('wtf');
  renderer.setSize(w, h);
};

var degToRad = (deg) => {
  return Math.PI / 180 * deg;
};

// STATS
var stats = new Stats();
stats.domElement.style.position = 'absolute';
stats.domElement.style.top = '0px';
stats.domElement.style.zIndex = 100;
document.body.appendChild(stats.domElement);

////////////
// CAMERA //
////////////
var VIEW_ANGLE = 45,
  ASPECT = w / h,
  NEAR = 0.1,
  FAR = 20000;
// set up camera
var camera = new THREE.PerspectiveCamera(VIEW_ANGLE, ASPECT, NEAR, FAR);
camera.rotation.z = -1.5708;

// add the camera to the scene
scene.add(camera);

var createGrid = require('./lib/grid');
var board = createGrid(5);
board.threeGroup.rotation.x = degToRad(0);
board.threeGroup.rotation.y = degToRad(45);
board.threeGroup.rotation.z = degToRad(20);
board.threeGroup.position.y = 1;
scene.add(board.threeGroup);

var ambientLight = new THREE.AmbientLight(0x000000);
scene.add(ambientLight);
camera.position.z = 0;
camera.position.y = 15;
camera.position.x = 0;

camera.lookAt(scene.position);
//camera.rotation.z = 0.785398;

var lights = [];
lights[0] = new THREE.PointLight(0xffffff, 1, 0);
lights[1] = new THREE.PointLight(0xffffff, 1, 0);
lights[2] = new THREE.PointLight(0xffffff, 1, 0);

lights[0].position.set(0, 5, 0);
lights[1].position.set(50, 100, 50);
lights[2].position.set(-100, -200, -100);

scene.add(lights[0]);
scene.add(lights[1]);
scene.add(lights[2]);

var createCards = require('./lib/cards'); // from './grid';
createCards().then((cards) => {
  cards.threeGroup.rotation.z = degToRad(10);
  //tiles.threeGroup.position.z = - 1.25;
  //tiles.threeGroup.rotation.z = -0.5;
  scene.add(cards.threeGroup);
});

var testText = require('./lib/text')('LOCI');
//testText.rotation.z += 1.5708/2;
//testText.rotation.z = -1.5708;
testText.rotation.y = degToRad(20);
testText.position.x = -4.5;
testText.position.z = 1.5;
scene.add(testText);

//var axes = require('./lib/axes')();
//testText.rotation.y = camera.rotation.y;
//testText.rotation.z = camera.rotation.z;
//scene.add(axes);

var render = function() {
  requestAnimationFrame(render);
  //testText.rotation.x += 0.1;
  //testText.rotation.y += 0.1;
  //testText.rotation.z += 0.1;
  renderer.render(scene, camera);
};

adjustSize();
render();

var updateTile = function (pos, type) {
  if(type){}
  pos = pos.toLowerCase();
  let x = pos.charCodeAt(0)-97;
  let y = pos.charCodeAt(1)-97;

  type = type.charAt(0).toUpperCase() + type.slice(1);
  console.log(pos, type);
  const imagePath = '/img/BasicBuildings/';

  /*const buildingTypes = [
    'Archives',
    'Chapel',
    'Fortress',
    'GuildHall',
    'Tavern'
  ];*/

  function buildUrl(type){
    return imagePath + type + '.jpg';
  }

  //var groundUrl = '/img/ground.jpg';
    var texture = new THREE.TextureLoader().load(buildUrl(type));
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    texture.repeat.set(1, 1);

    let tile = board.tiles[x*5+y];
    tile.material.map = texture;
};

window.addEventListener("resize", () => {
  adjustSize();
});

var socket = io();

  $(window).on('beforeunload', function() {
    socket.close();
    console.log('close socket');
  });

  var onSendMessage = () => {
    console.log('Send!');
    if ($('#messagebar').val() !== '') {
      socket.emit('message', {
        username: 'anon',
        message: $('#messagebar').val()
      });
      $('#messagebar').val('');
    }
  };

  var chatLogCleaner = () => {
    let log = $('#messagebar').val();
    if (log.length > 1000) {
      $('#messagebar').val(log.slice(0, 1000));
    }
    setTimeout(chatLogCleaner, 30);
  };
  chatLogCleaner();

  $('#messagebar').keydown(function(event) {
    var keypressed = event.keyCode || event.which;
    if (keypressed === 13) {
      onSendMessage();
    }
  });

  $('#sendButton').click(function() {
    onSendMessage();
  });

  $('#connectTwitch').click(function() {
    socket.emit('connectTwitch');
    console.log('connect socket');

    return false;
  });

  $('#disconnectTwitch').click(function() {
    $('#messages').empty();
    socket.emit('disconnectTwitch');
    console.log('disconnect socket');
  });

  socket.on('chatLog', function(msg) {
    console.log('chat log bruh');
    console.log('wtfsdsdsdsd');
    $('#chatBody').append(msg);
    console.log(msg);
    return false;
  });

  socket.on('playerAction', function(msg) {
    console.log('Client player messsage: ' + msg);
    $('#chatBody').append('ACTION>>> ', msg.message, '<br>');
    $("#chatBody").animate({
      scrollTop: $('#chatBody').prop("scrollHeight")
    }, "slow");
    let heyheyhey = msg.message.split(' ');
    updateTile(heyheyhey[0],heyheyhey[1]);
    return false;
  });

  socket.on('chatMessage', function(msg) {
    console.log('Client player chat messsage: ' + msg);
    $('#chatBody').append('Chat> ', msg.message, '<br>');
    $("#chatBody").animate({
      scrollTop: $('#chatBody').prop("scrollHeight")
    }, "slow");

    return false;
  });

},{"./lib/cards":3,"./lib/grid":4,"./lib/text":6}],2:[function(require,module,exports){
var buildAxes = (length) => {
  var axes = new THREE.Object3D();

  if (!length) {
    length = 10;
  }

  axes.add(buildAxis(new THREE.Vector3(0, 0, 0), new THREE.Vector3(length, 0, 0), 0xFF0000, false)); // +X
  axes.add(buildAxis(new THREE.Vector3(0, 0, 0), new THREE.Vector3(-length, 0, 0), 0xFF0000, true)); // -X
  axes.add(buildAxis(new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, length, 0), 0x00FF00, false)); // +Y
  axes.add(buildAxis(new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, -length, 0), 0x00FF00, true)); // -Y
  axes.add(buildAxis(new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 0, length), 0x0000FF, false)); // +Z
  axes.add(buildAxis(new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 0, -length), 0x0000FF, true)); // -Z

  return axes;
};

function buildAxis(src, dst, colorHex, dashed) {
  var geom = new THREE.Geometry(),
    mat;

  if (dashed) {
    mat = new THREE.LineDashedMaterial({
      linewidth: 3,
      color: colorHex,
      dashSize: 3,
      gapSize: 3
    });
  } else {
    mat = new THREE.LineBasicMaterial({
      linewidth: 3,
      color: colorHex
    });
  }

  geom.vertices.push(src.clone());
  geom.vertices.push(dst.clone());
  geom.computeLineDistances(); // This one is SUPER important, otherwise dashed lines will appear as simple plain lines

  var axis = new THREE.Line(geom, mat, THREE.LinePieces);

  return axis;

}

module.exports = buildAxes;

},{}],3:[function(require,module,exports){
const cardImages = [
  '/img/Cards/ArchiveCard.jpg',
  '/img/Cards/ChapelCard.jpg',
  '/img/Cards/FortressCard.jpg',
  '/img/Cards/GuildHallCard.jpg',
  '/img/Cards/TavernCard.jpg'
];
var cardTextures = [];

var createCards = () => {
  return new Promise(function(resolve) {
    var tiles = [];
    var tileGroup = new THREE.Group();

    function createTexture(url) {
      return new Promise(function(resolve) {
        let canvas = document.createElement("canvas");
        canvas.height = 512;
        canvas.width = 512;
        let context = canvas.getContext('2d');
        let imageObj = new Image();
        imageObj.src = url;
        let index = cardImages.indexOf(url);
        imageObj.onload = function() {
          console.log("IMAGE");
          context.drawImage(imageObj, 0, 0);
          cardTextures[index] = new THREE.Texture(canvas);
          cardTextures[index].needsUpdate = true;
          cardTextures[index].wrapS = THREE.RepeatWrapping;
          cardTextures[index].wrapT = THREE.RepeatWrapping;
          cardTextures[index].repeat.set(1, 1);
          resolve(cardTextures);
        };
      });
    }

    Promise
      .all(cardImages.map(createTexture))
      .then(() => {
        let index = 0;
        for (let j = -1 * Math.floor(cardTextures.length / 2); j <= Math.floor(cardTextures.length / 2); j++) {
          var geometry = new THREE.BoxGeometry(1, 0.2, 1);
          var material = new THREE.MeshLambertMaterial();
          material.map = cardTextures[index++];
          let tile = new THREE.Mesh(geometry, material);
          tile.position.set(5 * 1.05 - 0.5, 1, j * 1.2);
          tile.rotation.y = Math.PI / 180 * 90;
          tiles.push(tile);
          tileGroup.add(tiles[tiles.length - 1]);
        }
        resolve({
          tiles: tiles,
          threeGroup: tileGroup
        });
      });
  });
};

module.exports = createCards;

},{}],4:[function(require,module,exports){

//const imagePath = '/img/BasicBuildings/';

/*const buildingTypes = [
  'Archives',
  'Chapel',
  'Fortress',
  'GuildHall',
  'Tavern'
];*/

/*function buildUrl(type){
  return imagePath + type + '.jpg';
}*/

var groundUrl = '/img/ground.jpg';

var createGrid = (size) => {
  console.log(size);
  var grid = [];
  var gridGroup = new THREE.Group();
  if (!size) size = 5;
  // /console.log(buildingTypes[Math.floor(Math.random()*buildingTypes.length)]);
  //let url = buildUrl(buildingTypes[Math.floor(Math.random()*buildingTypes.length)]);


  for (let i = -1 * Math.floor(size / 2); i <= Math.floor(size / 2); i++) {
    for (let j = -1 * Math.floor(size / 2); j <= Math.floor(size / 2); j++) {
      let texture = new THREE.TextureLoader().load(groundUrl);
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      texture.repeat.set(1, 1);

      let geometry = new THREE.BoxGeometry(1, 0.2, 1);
      let material = new THREE.MeshLambertMaterial();
      material.map = texture;
      let cube;
      cube = new THREE.Mesh(geometry, material);
      cube.position.set(i * 1.05, 0, j * 1.05);
      grid.push(cube);
      gridGroup.add(grid[grid.length - 1]);
    }
  }
  return {
    tiles: grid,
    threeGroup: gridGroup
  };
};

module.exports = createGrid;

},{}],5:[function(require,module,exports){
var numRow = 5,
  numCol = 5;

var geometry = new THREE.BoxGeometry(1, 0.2, 1);

var randomTiles = (num, color) => {
  var material = new THREE.MeshPhongMaterial({
    color: color === 2 ? 0x0043ff : 0xfe0000,
    transparent: true,
    opacity: 1
  });

  let tiles = [];
  let tileGroup = new THREE.Group();
  for (let j = -1 * Math.floor(numCol / 2); j <= Math.floor(numCol / 2); j++) {
    let tile = new THREE.Mesh(geometry, material);
    if (color === 2) {
      tile.position.set(numRow * 1.05 - 0.5, 1, j * 1.2);
    } else {
      tile.position.set(j * 1.2, 1, numRow * 1.05 - 0.5);
    }
    tiles.push(tile);
    tileGroup.add(tiles[tiles.length - 1]);
  }
  return {
    tiles: tiles,
    threeGroup: tileGroup
  };
};

module.exports = randomTiles;

},{}],6:[function(require,module,exports){
var textGeometry = (str) => {
  var text3d = new THREE.TextGeometry(str, {
    size: 1,
    height: 0.5,
    curveSegments: 4,
    font: "helvetiker",
    bevelEnabled: true,
    bevelThickness: 0.2,
    bevelSize: 0.0
  });

  text3d.computeBoundingBox();
  var centerOffset = -0.5 * (text3d.boundingBox.max.x - text3d.boundingBox.min.x);
  //var centerOffsetY = -0.5 * (text3d.boundingBox.max.y - text3d.boundingBox.min.y);
  var material = new THREE.MeshFaceMaterial([
    new THREE.MeshBasicMaterial({
      color: 0x56BD65,
      overdraw: 0.5
    }),
    new THREE.MeshBasicMaterial({
      color: 0xFF0000,
      overdraw: 0.5
    })
  ]);

  var text = new THREE.Mesh(text3d, material);
  text.rotation.x = -1.5708;
  //testText.rotation.z = 1.5708;
  text.rotation.z = 1.5708;
  text.position.x = centerOffset;
  //text.position.z = -1 * centerOffsetY;
  //text.position.y = 5;
  //text.position.z = 0;

  return text;
};
module.exports = textGeometry;

},{}],7:[function(require,module,exports){
const imagePath = '/img/BasicBuildings/';

const buildingTypes = [
  'Archives',
  'Chapel',
  'Fortress',
  'GuildHall',
  'Tavern'
];

var waiting = false;
var resolveCbs = [];
var buildingTextures = null;

var createBuildingTextures = () => {
  waiting = true;
  buildingTextures = {};
  return new Promise(function(resolve) {
    function createTexture(url, type) {
      return new Promise(function(resolve) {
        let canvas = document.createElement("canvas");
        canvas.height = 512;
        canvas.width = 512;
        let context = canvas.getContext('2d');
        let imageObj = new Image();
        imageObj.src = url;
        imageObj.onload = function() {
          console.log("IMAGE");
          context.drawImage(imageObj, 0, 0);
          buildingTextures[type] = new THREE.Texture(canvas);
          buildingTextures[type].needsUpdate = true;
          buildingTextures[type].wrapS = THREE.RepeatWrapping;
          buildingTextures[type].wrapT = THREE.RepeatWrapping;
          buildingTextures[type].repeat.set(1, 1);
          resolve(buildingTextures);
        };
      });
    }

    /*function createBuildingTextures(type) {
      let urls = {
        normal: imagePath,
        gold: imagePath,
        dark: imagePath
      };
      return Promise
        .all(Object.keys(buildingTextures).map(createTexture))
        new Promise(function(resolve) {
        let canvas = document.createElement("canvas");
        canvas.height = 512;
        canvas.width = 512;
        let context = canvas.getContext('2d');
        let imageObj = new Image();
        imageObj.src = url;
        let index = cardImages.indexOf(url);
        imageObj.onload = function() {
          console.log("IMAGE");
          context.drawImage(imageObj, 0, 0);
          cardTextures[index] = new THREE.Texture(canvas);
          cardTextures[index].needsUpdate = true;
          cardTextures[index].wrapS = THREE.RepeatWrapping;
          cardTextures[index].wrapT = THREE.RepeatWrapping;
          cardTextures[index].repeat.set(1, 1);
          resolve(cardTextures);
        };
      });
    }*/

    Promise
      .all(buildingTypes.map((type) => {
        return createTexture(imagePath + type + '.jpg', type);
      }))
      .then(() => {
        resolve(buildingTextures);
        if (resolveCbs.length > 0) {
          waiting = false;
          resolveCbs.map((resolve) => {
            resolve(buildingTextures);
          });
        }
      });
  });
};
var getBuildingTextures = () => {
  return new Promise((resolve) => {
    if (buildingTextures !== null) {
      resolve(buildingTextures);
    } else if (waiting) {
      resolveCbs.append(resolve);
    } else {
      waiting = true;
      resolve(createBuildingTextures());
    }
  });
};

module.exports = getBuildingTextures();

},{}],8:[function(require,module,exports){
const tileImages = [
  '/img/ground.jpg',
  '/img/BasicBuildings/Archives.jpg',
  '/img/BasicBuildings/Archives_DarkPath.jpg',
  '/img/BasicBuildings/Archives_GoldPath.jpg',
  '/img/BasicBuildings/Chapel.jpg',
  '/img/BasicBuildings/Chapel_DarkPath.jpg',
  '/img/BasicBuildings/Chapel_GoldPath.jpg',
  '/img/BasicBuildings/Fortress.jpg',
  '/img/BasicBuildings/Fortress_Dark Path.jpg',
  '/img/BasicBuildings/Fortress_Gold Path.jpg',
  '/img/BasicBuildings/Guild Hall.jpg',
  '/img/BasicBuildings/Guild Hall_GoldPath.jpg',
  '/img/BasicBuildings/GuildHall_DarkPath.jpg',
  '/img/BasicBuildings/Tavern.jpg',
  '/img/BasicBuildings/Tavern_DarkPath.jpg',
  '/img/BasicBuildings/Tavern_GoldPath.jpg'
];

class Tile {
  constructor(type, player) {
    this._image = tileImages[0];
    this._type = type;
    this._player = player;
  }

  get type() {
    return this._type;
  }

  get player() {
    return this._player;
  }

  modify(type, player) {
    return `${type} ${player}`;
  }

  toString() {
    return `${this.type} ${this.player}`;
  }
}

module.exports = Tile;

},{}]},{},[1,2,3,4,5,6,7,8]);
